li:has(> .languageButton) {
    display: flex;
    flex-direction: row;
}

.languageButton:hover {
    color: var(--default-primary);
    text-decoration: underline;
}

.language .activeLanguage {
    color: var(--default-primary);
    text-decoration: underline;
}

.languageDivider {
    padding: 0;
    border: none;
    border-right: 1px solid;
    height: 1rem;
    margin: 0 0.5rem;
}

.skiplink {
    width: auto;
}
